<template>
  <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
    <title>i-geo-line</title>
    <path
        d="M7.975 15.869l-0.35-0.419c-0.056-0.069-0.112-0.131-0.169-0.2-0.131-0.156-0.269-0.319-0.406-0.481-1.081-1.294-2.069-2.688-2.919-4.15-0.594-0.981-1.075-2.056-1.431-3.2-0.344-1.094-0.35-2.256-0.019-3.369v-0.006c0.444-1.425 1.419-2.587 2.744-3.281 1.319-0.688 2.831-0.825 4.256-0.375 1.156 0.363 2.175 1.1 2.869 2.075 0.512 0.712 0.844 1.55 0.969 2.419v0.006c0.131 1.056 0 2.131-0.387 3.106-0.381 1.031-0.875 2.050-1.475 3.013-0.925 1.506-1.988 2.956-3.156 4.313-0.056 0.069-0.1 0.119-0.144 0.156l-0.381 0.394zM3.638 4.344c-0.269 0.919-0.263 1.881 0.019 2.787 0.331 1.069 0.781 2.069 1.338 2.981l0.006 0.006c0.825 1.412 1.775 2.763 2.825 4.019l0.006 0.006c0.063 0.075 0.125 0.15 0.188 0.225 1.031-1.225 1.969-2.525 2.794-3.875 0.569-0.912 1.037-1.869 1.394-2.838l0.006-0.013c0.325-0.825 0.438-1.731 0.325-2.619-0.1-0.706-0.369-1.388-0.787-1.969-0.575-0.8-1.412-1.406-2.363-1.706-1.169-0.369-2.412-0.256-3.494 0.313-1.088 0.556-1.888 1.512-2.256 2.681z"></path>
    <path
        d="M8 7.15c-1.156 0-2.094-0.938-2.094-2.094s0.938-2.094 2.094-2.094 2.094 0.938 2.094 2.094-0.938 2.094-2.094 2.094zM8 3.962c-0.6 0-1.094 0.494-1.094 1.094s0.494 1.094 1.094 1.094 1.094-0.494 1.094-1.094-0.494-1.094-1.094-1.094z"></path>
  </svg>
</template>
<script setup lang="ts">
</script>