<template>
  <div class="product_list-school-container">
    <div class="product_list-school-container__info">
      <img
        class="school_image"
        :src="school.image_link.medium.url"
        :alt="school.name" />
      <span
        v-if="school.text"
        class="school_name">{{ school.text }}</span>
      <span
        v-if="school.completeAddress"
        class="school_address">{{ school.completeAddress }}</span>
      <span
        v-if="school.urlSchoolMap"
        class="school_map_link">
        <UniformsLocationIcon />
        <a
          :href="school.urlSchoolMap"
          target="_blank">
          {{ $t('uniforms.school_products_list-location') }}
        </a>
      </span>
    </div>
    <div class="product_list-school-container__locate-other-school">
      <i class="icon search"></i>
      <a
        :href="uniformsHomePath"
        class="school_search_other_school">
        {{ $t('uniforms.school_products_list-locate-other-school') }}
      </a>
    </div>
  </div>
</template>
<script>
  import UniformsLocationIcon from 'Components/Uniforms/UniformsLocationIcon.vue';
  import { mapGetter } from 'CommonUtils/store/state.js';

  export default {
    name: 'UniformsProductListHeader',
    components: { UniformsLocationIcon },
    props: {
      school: { type: Object, required: true },
    },
    setup() {
      const baseUrl = mapGetter('page/getBaseUrl');
      const slugs = mapGetter('page/getSlugs');

      const uniformsHomePath = computed(() => {
        return `${baseUrl.value}/${slugs.value?.hierarchy?.[0]}`;
      });

      return {
        uniformsHomePath,
      };
    },
  };
</script>
